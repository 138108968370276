import fetch from 'auth/FetchInterceptor'

const AuthService = {}

// AUTH ROUTES

AuthService.login = function (data) {
	return fetch({
		url: '/auth/login',
		method: 'post',
		data: data
	})
}

AuthService.register = function (data) {
	return fetch({
		url: '/auth/register',
		method: 'post',
		data: data
	})
}

/*AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post'
	})
}*/

AuthService.me = function () {
	return fetch({
		url: '/auth/me',
		method: 'get'
	})
}

AuthService.updateMe = function (data) {
	return fetch({
		url: '/auth/me/update',
		method: 'post',
        data
	})
}

AuthService.updatePassword = function (data) {
	return fetch({
		url: '/auth/me/update/password',
		method: 'post',
        data
	})
}

// AUTH ROUTES

// PHARMACY ROUTES

AuthService.list_pharmacies = function() {
    return fetch({
        url: '/pharmacy/list',
        method: 'get'
    })
}

AuthService.fetch_pharmacy = function(_id) {
    return fetch({
        url: '/pharmacy/view/' + _id,
        method: 'get'
    });
}

AuthService.deletePharmacy = function (data) {
    return fetch({
        url: '/pharmacy/delete',
        method: 'post',
        data
    });
}

AuthService.updatePharmacy = function (data) {
    return fetch({
        url: '/pharmacy/update',
        method: 'post',
        data
    });
}

AuthService.createPharmacy = function (data) {
    return fetch({
        url: '/pharmacy/create',
        method: 'post',
        data
    });
}

// END PHARMACY ROUTES

// ONCALL ROUTES

AuthService.addTime = function (data) {
    return fetch({
        url: '/oncall/create',
        method: 'post',
        data
    });
}

AuthService.deleteTimeRange = function (data) {
    return fetch({
        url: '/oncall/delete',
        method: 'post',
        data
    });
}

// END ONCALL ROUTES

// DASHBOARD ROUTES
AuthService.dashboardContent = function () {
    return fetch({
        url: '/dashboard/info',
        method: 'GET'
    });
}

AuthService.pharmaciesPerCity = function () {
    return fetch({
        url: '/dashboard/pharmaciesPerCity',
        method: 'GET'
    });
}

// ANALYTICS ROUTES

AuthService.analyticsRecords = function (period) {
    return fetch({
        url: '/analytics/records/' + period,
        method: 'GET'
    });
}

// PRODUCT ROUTES

AuthService.productList = function () {
    return fetch({
        url: '/product/list',
        method: 'GET'
    });
}

AuthService.addProduct = function (data) {
    return fetch({
        url: '/product/add',
        method: 'POST',
        data
    });
}

AuthService.fetchProduct = function (data) {
    return fetch({
        url: '/product/view/' + data,
        method: 'GET',
    });
}

AuthService.updateProduct = function (data) {
    return fetch({
        url: '/product/update',
        method: 'POST',
        data
    });
}

AuthService.deleteProduct = function (data) {
    return fetch({
        url: '/product/delete',
        method: 'POST',
        data
    });
}

// ORDER ROUTES

AuthService.orderList = function () {
    return fetch({
        url: '/order/list',
        method: 'GET'
    });
}

AuthService.addOrder = function (data) {
    return fetch({
        url: '/order/add',
        method: 'POST',
        data
    });
}

AuthService.fetchOrder = function (data) {
    return fetch({
        url: '/order/view/' + data,
        method: 'GET',
    });
}

AuthService.updateOrder = function (data) {
    return fetch({
        url: '/order/update',
        method: 'POST',
        data
    });
}

AuthService.deleteOrder = function (data) {
    return fetch({
        url: '/order/delete',
        method: 'POST',
        data
    });
}

AuthService.matchOrders = function (data) {
    return fetch({
        url: '/order/match_order',
        method: 'POST',
        data
    });
}

AuthService.orderWithoutPayment = function () {
    return fetch({
        url: '/order/orderWithoutPayment',
        method: 'GET'
    });
}

// CUSTOMER ROUTES

AuthService.customerList = function () {
    return fetch({
        url: '/customer/list',
        method: 'GET'
    });
}

AuthService.addCustomer = function (data) {
    return fetch({
        url: '/customer/add',
        method: 'POST',
        data
    });
}

AuthService.fetchCustomer = function (data) {
    return fetch({
        url: '/customer/view/' + data,
        method: 'GET',
    });
}

AuthService.updateCustomer = function (data) {
    return fetch({
        url: '/customer/update',
        method: 'POST',
        data
    });
}

AuthService.deleteCustomer = function (data) {
    return fetch({
        url: '/customer/delete',
        method: 'POST',
        data
    });
}

// USER ROUTES

AuthService.userList = function () {
    return fetch({
        url: '/user/list',
        method: 'GET'
    });
}

AuthService.addUser = function (data) {
    return fetch({
        url: '/user/add',
        method: 'POST',
        data
    });
}

AuthService.fetchUser = function (data) {
    return fetch({
        url: '/user/view/' + data,
        method: 'GET',
    });
}

AuthService.updateUser = function (data) {
    return fetch({
        url: '/user/update',
        method: 'POST',
        data
    });
}

AuthService.deleteUser = function (data) {
    return fetch({
        url: '/user/delete',
        method: 'POST',
        data
    });
}

// PAYMENT ROUTES

AuthService.paymentList = function () {
    return fetch({
        url: '/payment/list',
        method: 'GET'
    });
}

AuthService.addPayment = function (data) {
    return fetch({
        url: '/payment/add',
        method: 'POST',
        data
    });
}

AuthService.fetchPayment = function (data) {
    return fetch({
        url: '/payment/view/' + data,
        method: 'GET',
    });
}

AuthService.updatePayment = function (data) {
    return fetch({
        url: '/payment/update',
        method: 'POST',
        data
    });
}

AuthService.deletePayment = function (data) {
    return fetch({
        url: '/payment/delete',
        method: 'POST',
        data
    });
}

export default AuthService;