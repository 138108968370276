import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'services/AuthService';
import { signOut } from './authSlice';

export const initialState = {
    info: {},
    loading: false,
    showMessage: false,
    message: {}
}

export const userInfo = createAsyncThunk('user/userInfo', async (_, { dispatch }) => {
	try {
		const response = await AuthService.me();
        if (response?.status === 'success')
            return response?.data;
        dispatch(signOut());
	} catch (err) {
        dispatch(signOut());
	}
})

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
            .addCase(userInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(userInfo.fulfilled, (state, action) => {
                state.info = action.payload
                state.loading = false;
            })
	},
})

export const { 
	setUser
} = userSlice.actions

export default userSlice.reducer