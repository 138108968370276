import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'default',
        path: `${APP_PREFIX_PATH}/home`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'pharmacy',
        path: `${APP_PREFIX_PATH}/pharmacy`,
        component: React.lazy(() => import('views/app-views/pharmacy/list')),
    },
    {
        key: 'pharmacy.edit',
        path: `${APP_PREFIX_PATH}/pharmacy/edit/:_id`,
        component: React.lazy(() => import('views/app-views/pharmacy/edit')),
    },
    {
        key: 'pharmacy.add',
        path: `${APP_PREFIX_PATH}/pharmacy/add`,
        component: React.lazy(() => import('views/app-views/pharmacy/edit')),
    },
    {
        key: 'settings.profile',
        path: `${APP_PREFIX_PATH}/profile/*`,
        component: React.lazy(() => import('views/app-views/setting/index')),
    },
    {
        key: 'product.index',
        path: `${APP_PREFIX_PATH}/product`,
        component: React.lazy(() => import('views/app-views/product/index')),
    },
    {
        key: 'product.add.index',
        path: `${APP_PREFIX_PATH}/product/add`,
        component: React.lazy(() => import('views/app-views/product/add/index')),
    },
    {
        key: 'product.edit',
        path: `${APP_PREFIX_PATH}/product/edit/:_id`,
        component: React.lazy(() => import('views/app-views/product/add/index')),
    },
    {
        key: 'order.index',
        path: `${APP_PREFIX_PATH}/order`,
        component: React.lazy(() => import('views/app-views/order/index')),
    },
    {
        key: 'order.add.index',
        path: `${APP_PREFIX_PATH}/order/add`,
        component: React.lazy(() => import('views/app-views/order/add/index')),
    },
    {
        key: 'order.edit',
        path: `${APP_PREFIX_PATH}/order/edit/:_id`,
        component: React.lazy(() => import('views/app-views/order/add/index')),
    },
    {
        key: 'customer.index',
        path: `${APP_PREFIX_PATH}/customer`,
        component: React.lazy(() => import('views/app-views/customer/index')),
    },
    {
        key: 'customer.add.index',
        path: `${APP_PREFIX_PATH}/customer/add`,
        component: React.lazy(() => import('views/app-views/customer/add/index')),
    },
    {
        key: 'customer.edit',
        path: `${APP_PREFIX_PATH}/customer/edit/:_id`,
        component: React.lazy(() => import('views/app-views/customer/add/index')),
    },
    {
        key: 'users.index',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users/index')),
    },
    {
        key: 'users.add.index',
        path: `${APP_PREFIX_PATH}/users/add`,
        component: React.lazy(() => import('views/app-views/users/tabs/index')),
    },
    {
        key: 'users.edit',
        path: `${APP_PREFIX_PATH}/users/edit/:_id`,
        component: React.lazy(() => import('views/app-views/users/tabs/index')),
    },
    {
        key: 'payment.index',
        path: `${APP_PREFIX_PATH}/payment`,
        component: React.lazy(() => import('views/app-views/payment/index')),
    },
    {
        key: 'payment.add.index',
        path: `${APP_PREFIX_PATH}/payment/add`,
        component: React.lazy(() => import('views/app-views/payment/add/index')),
    },
    {
        key: 'payment.edit',
        path: `${APP_PREFIX_PATH}/payment/edit/:_id`,
        component: React.lazy(() => import('views/app-views/payment/add/index')),
    },
]